import {
    FC,
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FloCToastTypes,
    ToastListElement,
    ToastParams,
} from '@components/FloCElements/FloCElements.model';
import FloCToast from '@components/FloCElements/FloCToast';
import FloConfirmModal from '@components/FloCElements/FloConfirmModal';
import { useToastProcessing } from './Processors/ToastProcessing';
import {
    ConfirmContextProps,
    InfoErrorConfirmContextProps,
} from './InforErrorConfirm.model';

const InfoErrorConfirmContext = createContext<
    InfoErrorConfirmContextProps | undefined
>(undefined);

const toastDefaults = { show: false, type: FloCToastTypes.INFO, message: '' };
const confirmDefaults = {
    isVisible: false,
    title: '',
    body: '',
};

export const InfoErrorConfirmProvider: FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [toast, setToast] = useState<
        ToastParams & {
            show: boolean;
        }
    >(toastDefaults);

    const [incomingToasts, setIncomingToasts] = useState<
        Array<ToastListElement>
    >([]);

    const [confirm, setConfirm] =
        useState<ConfirmContextProps>(confirmDefaults);

    const showToast = useCallback((params: ToastParams) => {
        const { options: optionsSet, type } = params;

        const options = optionsSet ? { ...optionsSet } : {};

        if (!options.color) {
            switch (type) {
                case 'ERROR':
                    options.color = 'danger';
                    break;
                case 'WARNING':
                    options.color = 'warning';
                    break;
                case 'SUCCESS':
                    options.color = 'success';
                    break;
                default:
                    options.color = 'info';
            }
        }

        if (!options.autohide) options.autohide = true;

        if (!options.delay) options.delay = 5000;

        setToast({ ...params, options, show: true });
    }, []);

    const hideToast = () => setToast(toastDefaults);

    const showConfirm = useCallback((props: ConfirmContextProps) => {
        setConfirm({ ...props, isVisible: true });
    }, []);

    const hideConfirm = useCallback(
        () =>
            setConfirm({
                ...confirmDefaults,
                title: confirm.title,
                body: confirm.body,
            }),
        [confirm.body, confirm.title]
    );

    useEffect(() => {
        const handleLocationChange = () => {
            if (confirm.isVisible) {
                hideConfirm();
            }
        };

        window.addEventListener('popstate', handleLocationChange);

        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, [confirm.isVisible, hideConfirm]);

    const { solverEndInterpreter } = useToastProcessing();

    const infoError = useMemo(
        () => ({
            pipeline: {
                get: () => incomingToasts,
                add: (element: ToastListElement) => {
                    setIncomingToasts((prevToasts) => [...prevToasts, element]);
                },
                remove: (id: string) => {
                    setIncomingToasts((prevToasts) =>
                        prevToasts.filter((t) => t.id !== id)
                    );
                },
                flush: () => setIncomingToasts([]),
            },
            processor: [
                {
                    name: 'solver-end',
                    callback: solverEndInterpreter,
                },
            ],
        }),
        [incomingToasts, solverEndInterpreter]
    );

    const contextValue = useMemo(
        () => ({
            infoError,
            toast: showToast,
            confirm: showConfirm,
            cleanUp: () => {
                hideConfirm();
            },
        }),
        [showToast, showConfirm, hideConfirm, infoError]
    );

    return (
        <InfoErrorConfirmContext.Provider value={contextValue}>
            {children}
            <FloCToast
                toastShow={toast.show}
                type={toast.type}
                message={toast.message}
                options={{
                    ...toast.options,
                    onClose: hideToast,
                }}
            />
            <FloConfirmModal
                className="globalConfirmModal"
                isVisible={confirm.isVisible}
                title={confirm.title}
                body={confirm.body}
                onClose={() => {
                    if (confirm.onClose) confirm.onClose();
                    hideConfirm();
                }}
                onCancel={() => {
                    if (confirm.onCancel) confirm.onCancel();
                    hideConfirm();
                }}
                onConfirm={() => {
                    if (confirm.onConfirm) confirm.onConfirm();
                    hideConfirm();
                }}
            />
        </InfoErrorConfirmContext.Provider>
    );
};

export const useInfoErrorConfirm = () => {
    const context = useContext(InfoErrorConfirmContext);

    if (!context) {
        throw new Error(
            'useInfoErrorConfirm must be used within a InfoErrorConfirmProvider'
        );
    }

    return context;
};
