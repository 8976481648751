import { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ArgumentArray } from 'classnames';
import { CIconProps } from '@coreui/icons-react/dist/CIcon';
import { CButtonProps } from '@coreui/react-pro/dist/components/button/CButton';
import { CBadgeProps } from '@coreui/react-pro/dist/components/badge/CBadge';
import { Colors } from '@coreui/react-pro/dist/types';

export interface FloCElementClasses extends ArgumentArray {}

export type FloCIconDefinition = {
    id?: string;
    symbol: string[];
    width?: number;
    size?: CIconProps['size'];
};
export interface FloCButtonProps extends Omit<CButtonProps, 'className'> {
    className?: FloCElementClasses;
    icon?: FloCIconDefinition | ReactNode;
    render?: boolean;
    noVariant?: boolean;
}

export interface FloCBadgeProps extends Omit<CBadgeProps, 'className'> {
    containerTestId: string;
    containerClasses: FloCElementClasses;
    className?: FloCElementClasses;
    content: string | number;
    render?: boolean;
}

export const FloCElementClassesShape = PropTypes.arrayOf(
    PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        (
            props: { [k: string]: unknown },
            propName: string,
            componentName: string
        ) => {
            const propValue = props[propName];
            const valueType = typeof propValue;

            if (valueType !== 'object' || Array.isArray(propValue)) {
                return new Error(
                    `Invalid prop '${propName}' of type '${valueType}' supplied to '${componentName}', expected an object.`
                );
            }

            return null;
        },
    ])
);

export const FloCIconDefinitionShape = PropTypes.shape({
    id: PropTypes.string,
    symbol: PropTypes.arrayOf(PropTypes.string).isRequired,
    width: PropTypes.number,
    size: PropTypes.oneOf([
        'custom',
        'custom-size',
        'sm',
        'lg',
        'xl',
        'xxl',
        '3xl',
        '4xl',
        '5xl',
        '6xl',
        '7xl',
        '8xl',
        '9xl',
    ]),
});

export const FloCBadgePropsShape = {
    render: PropTypes.bool,
    containerTestId: PropTypes.string.isRequired,
    containerClasses: FloCElementClassesShape,
    color: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: FloCElementClassesShape,
};

export const FloCButtonPropsShape = {
    render: PropTypes.bool,
    color: PropTypes.string,
    icon: PropTypes.oneOfType([
        FloCIconDefinitionShape,
        PropTypes.node,
    ]) as PropTypes.Validator<FloCIconDefinition | ReactNode>,
    disabled: PropTypes.bool,
    className: FloCElementClassesShape,
    onClick: PropTypes.func.isRequired,
};

export const FloCElementPropsDefaults = {
    render: true,
    color: 'dark',
};

export type CallbackFn = () => void;

export enum FloCToastTypes {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
}

export type FloCToastOptions = {
    autohide?: boolean;
    delay?: number;
    onClose?: CallbackFn;
    placement?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
    color?: Colors;
};

export type ToastParams = {
    type: FloCToastTypes;
    message: string;
    options?: FloCToastOptions;
};

export type ToastListElementCallbackResponse = {
    id?: string;
    show: boolean;
    toastType?: FloCToastTypes;
    message?: string;
};

export type ToastListElement = {
    id: string;
    name: string;
    url: string;
};

export type ToastListProcessor<T> = {
    name: string;
    callback: (data: T) => ToastListElementCallbackResponse;
};

export type ConfirmParams = {
    title: string;
    body: string | JSX.Element;
    onConfirm: CallbackFn;
    onCancel: CallbackFn;
    onClose: CallbackFn;
};
