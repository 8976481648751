import { v4 as uuidv4 } from 'uuid';
import { FloTypes, VRP } from '@flo-concepts/flo-types';
import { cloneDeep, isEqual } from 'lodash';
import { sessionTypesDefaults } from '@hooks/useSessionTypes';
import {
    CreatorResultRow,
    DQRule,
    ResourceFormResultRow,
    SessionCreatorStatusTypes,
    SessionOperationConfig,
    StatusBadgeType,
} from './SessionCreator.model';

export const initialWaypointValue: VRP.Hydrated.Waypoint = {
    id: '',
    name: '',
    location: {
        latitude: null,
        longitude: null,
        waypointID: '',
    },
    customers: [] as Array<VRP.Hydrated.Customer>,
};

export const createSessionFromOrdersRules = (payload: Array<string>) => [
    {
        id: uuidv4(),
        name: 'FILTER_BY_ZONES',
        config: sessionTypesDefaults,
        enabled: false,
        payload: [] as Array<string>,
    },
    {
        id: uuidv4(),
        name: 'FILTER_BY_ORDER',
        enabled: true,
        payload,
    },
];

export const createSessionRules = (
    rulesConfig: SessionOperationConfig,
    compound: CreatorResultRow
) => [
    {
        id: uuidv4(),
        name: 'FILTER_BY_ZONES',
        config: rulesConfig,
        enabled: true,
        payload: compound.destinations.zones.reduce(
            (acc, curr) => [...acc, `${compound.source.name}|${curr}`],
            []
        ),
    },
];

export const createSessionDescription = (
    compound: CreatorResultRow,
    sessionOperationsDescription?: string
) => {
    return `${compound.source.name}: ${
        compound.destinations?.description
            ? compound.destinations?.description
            : compound.destinations?.zones.join(', ')
    }${
        sessionOperationsDescription ? ` (${sessionOperationsDescription})` : ''
    }`;
};

export const createOperationsDescription = (
    rulesConfig: SessionOperationConfig
) => {
    return Object.keys(rulesConfig)
        .filter(
            (k) => k !== 'FH' && rulesConfig[k as keyof SessionOperationConfig]
        )
        .join('/');
};

export const createSessionPayload = (
    results: Array<CreatorResultRow | ResourceFormResultRow>,
    dqRules: Array<DQRule>,
    createdBy: string,
    sessionDescription?: string,
    createFromOrders?: Array<string>
): Array<FloTypes.Session> => {
    const isCreatedFromOrders = createFromOrders?.length > 0;
    const payloadResult = results.reduce((acc, compound) => {
        const rulesConfig: SessionOperationConfig =
            compound.operationFlags ?? sessionTypesDefaults;
        const sessionOperationsDescription =
            createOperationsDescription(rulesConfig);

        const dqRulesConfig = dqRules.reduce((acc, rule) => {
            return rule.enabled &&
                !['FILTER_BY_ZONES', 'FILTER_BY_ORDER'].includes(rule.ruleName)
                ? [
                      ...acc,
                      {
                          id: uuidv4(),
                          name: rule.ruleName,
                          enabled: true,
                          payload: rule.payload,
                      },
                  ]
                : acc;
        }, []);

        const sessionRules = !isCreatedFromOrders
            ? createSessionRules(rulesConfig, compound as CreatorResultRow)
            : createSessionFromOrdersRules(createFromOrders);

        const getResourceStartWaypoint = (
            origin: FloTypes.Place,
            startWaypoint?: VRP.Hydrated.Waypoint
        ): VRP.Hydrated.Waypoint => {
            const init = (waypoint: VRP.Hydrated.Waypoint): boolean => {
                if (
                    isEqual(initialWaypointValue, waypoint) ||
                    !waypoint?.id ||
                    !waypoint?.name ||
                    !waypoint?.location?.latitude ||
                    !waypoint?.location?.longitude
                ) {
                    return false;
                }
                return true;
            };

            if (init(startWaypoint)) {
                return startWaypoint;
            }
            return {
                id: origin.placeID,
                name: origin.name,
                location: cloneDeep(origin.location),
                customers: [
                    {
                        place: cloneDeep(origin),
                    },
                ],
            };
        };

        const sessionResult = {
            sessionID: uuidv4(),
            description:
                sessionDescription ??
                createSessionDescription(
                    compound as CreatorResultRow,
                    sessionOperationsDescription
                ),
            params: {
                runIterationOnCreate: compound.runSolver ?? false,
                rules: [...sessionRules, ...dqRulesConfig],
            },
            orders: [] as Array<FloTypes.Hydrated.Order>,
            resources: (isCreatedFromOrders
                ? [compound.resource]
                : compound.resources
            )
                .reduce(
                    (rcs, { id, name, type, origin, start, end, amount }) => [
                        ...rcs,
                        ...Array(amount).fill({
                            id,
                            name,
                            type,
                            origin,
                            start: {
                                time: start?.time,
                                waypoint: getResourceStartWaypoint(
                                    origin,
                                    start?.waypoint
                                ),
                            },
                            end,
                        }),
                    ],
                    []
                )
                .map((res) => ({ ...res, id: uuidv4() })),
            initRoutes: [] as Array<VRP.Hydrated.Load>,
            creator: 'HUMAN',
            createdBy,
            recordStatus: 'NEW',
        };

        return [...acc, ...[sessionResult]];
    }, []);

    return payloadResult;
};

export const combineIntoSession = (
    payloads: Array<FloTypes.Session>
): Array<FloTypes.Session> => {
    if (payloads.length < 2) return payloads;

    const isCreateFromOrders =
        payloads[0].params?.rules?.some((r) => r.name === 'FILTER_BY_ORDER') ||
        false;

    const combinedDescription = isCreateFromOrders
        ? payloads[0].description
        : payloads.map((sess) => sess.description).join(' & ');

    const combinedParams = {
        runIterationOnCreate:
            payloads[0]?.params?.runIterationOnCreate ?? false,
        rules: isCreateFromOrders
            ? payloads[0].params.rules
            : [
                  {
                      id: '',
                      name: 'FILTER_BY_ZONES',
                      enabled: true,
                      payload: payloads
                          .flatMap((sess) =>
                              sess.params.rules.flatMap((rule) => rule.payload)
                          )
                          .filter((r) => !!r),
                  },
              ],
    };

    const combinedResources = payloads.flatMap((sess) => sess.resources);

    return [
        {
            ...payloads[0],
            description: combinedDescription,
            params: combinedParams,
            resources: combinedResources,
        },
    ];
};

export const processSessions = async (
    sessions: Array<FloTypes.Session>,
    processSingleSession: (sessionSetup: FloTypes.Session) => Promise<boolean>,
    refreshSessions: () => Promise<void>
) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const sess of sessions) {
        try {
            // eslint-disable-next-line no-await-in-loop
            await processSingleSession(sess);
        } catch (e) {
            console.error(e);
        }
    }

    await refreshSessions();
};

export const prepareStatusBadge = { color: 'secondary', message: 'Preparing' };

export const getStatusBadgeDescription = (
    currentSessionStatus: boolean | undefined
): StatusBadgeType => {
    if (typeof currentSessionStatus !== 'boolean')
        return { color: 'info', message: 'In progress' };

    return {
        color: currentSessionStatus ? 'success' : 'warning',
        message: currentSessionStatus ? 'Created' : 'Failed',
    };
};

export const getProgressBarColor = (
    progressPercentage: number,
    sessionFailed: boolean
): SessionCreatorStatusTypes => {
    if (progressPercentage < 100) return 'info';
    if (sessionFailed) return 'warning';
    return 'success';
};

export const createWaypointFromPlace = (
    place: FloTypes.Place
): VRP.Hydrated.Waypoint => {
    return {
        id: place.placeID,
        name: place.name,
        location: {
            waypointID: place.location.waypointID,
            latitude: place.location.latitude,
            longitude: place.location.longitude,
        },
        customers: [{ place }],
    };
};

export const getWaypointForTransportResource = (
    places: Array<FloTypes.Place>,
    sourceID: string
): VRP.Hydrated.Waypoint => {
    const place = places.find((p) => p.placeID === sourceID);

    if (place) {
        return {
            id: place.placeID,
            name: place.name,
            location: {
                waypointID: place.location.waypointID,
                latitude: place.location.latitude,
                longitude: place.location.longitude,
            },
            customers: [{ place }],
        };
    }
    return cloneDeep(initialWaypointValue);
};

export const isoDateFormatter = (dateString?: string): string => {
    try {
        return (dateString ? new Date(dateString) : new Date())
            .toISOString()
            .split('T')?.[0];
    } catch (err) {
        return undefined;
    }
};

export const dateTimeFormatter = <T extends string | undefined>(
    dateString: T
): T => dateString?.replace(/T/, ' ') as T;
