import { useRef } from 'react';
import { FloCToastTypes } from '@components/FloCElements/FloCElements.model';
import { SolverInfo, SolverIteration } from '@containers/session/Session.model';

export const useToastProcessing = () => {
    const processedIterationsRef = useRef<Array<string>>([]);

    const solverEndInterpreter = (data: SolverInfo) => {
        if (data.iterations) {
            const createdIterations = data.iterations
                .filter(
                    (iteration: SolverIteration) =>
                        iteration.status === 'CREATED' &&
                        !processedIterationsRef.current.includes(iteration.id)
                )
                .map((iteration: SolverIteration) => iteration.id);

            if (createdIterations.length) {
                processedIterationsRef.current = [
                    ...processedIterationsRef.current,
                    ...createdIterations,
                ];
            }

            const finishedIterations = data.iterations.filter(
                (iteration: SolverIteration) =>
                    processedIterationsRef.current.includes(iteration.id) &&
                    [
                        'succeeded',
                        'succeeded_processing',
                        'completed',
                        'failed',
                    ].includes(iteration.status?.toLowerCase())
            );

            if (finishedIterations.length) {
                const processIteration = finishedIterations[0];
                processedIterationsRef.current =
                    processedIterationsRef.current.filter(
                        (iid) => iid !== processIteration.id
                    );

                const success =
                    processIteration?.status.toLowerCase() !== 'failed';

                return {
                    show: true,
                    toastType: success
                        ? FloCToastTypes.SUCCESS
                        : FloCToastTypes.WARNING,
                    message: `Solver Run has ${
                        success ? 'ended successfully' : 'failed'
                    }`,
                };
            }
        }

        return { show: false };
    };

    return { solverEndInterpreter };
};
