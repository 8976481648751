/* eslint-disable no-restricted-syntax */
import { AxiosError } from 'axios';
import { ToastListElement } from '@components/FloCElements/FloCElements.model';
import {
    WhiteListErrorReturn,
    WhitelistErrorResponse,
} from './InforErrorConfirm.model';

export const findMatchingToasts = (
    responseUrl: string,
    toasts: Array<ToastListElement>
): Array<ToastListElement> => {
    const matchingToasts = toasts.filter((toast) => {
        if (toast.url.includes('{uuid}')) {
            const regxPattern = `^${toast.url
                .replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
                .replace(
                    /\\{uuid\\}/g,
                    '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}'
                )}$`;

            const re = new RegExp(regxPattern);
            return re.test(responseUrl);
        }

        return toast.url === responseUrl;
    });

    return matchingToasts;
};

export const checkWhiteListedErrors = (
    error: AxiosError<WhitelistErrorResponse>
): WhiteListErrorReturn => {
    const responseData = error.response?.data;
    const responseStatus = error.response?.status;

    const isNetworkFailure = error.code === 'ERR_NETWORK';

    const errorMessage = isNetworkFailure
        ? 'System is down'
        : responseData?.message;

    const isKeycloakError =
        [401, 403].includes(responseStatus) &&
        (responseData?.error === 'invalid_token' ||
            errorMessage.includes('Token claims invalid') ||
            errorMessage.includes('Keycloak') ||
            responseData?.error_description?.includes('Token claims invalid'));

    const uuidPattern =
        '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

    const whiteListedEndpoints = [
        `/session/${uuidPattern}/iteration`,
        `/session/${uuidPattern}/pickIteration`,
        `/workbench/triggerSync\\?email=[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}`,
        `/load/${uuidPattern}`,
        `/template/delete/${uuidPattern}`,
        `/workbench/lastStatus`,
    ];

    const isWhitelisted = whiteListedEndpoints.some((pattern) => {
        const regex = new RegExp(`^${pattern}$`);
        return regex.test(error.config.url);
    });

    return {
        show: !isKeycloakError && (isWhitelisted || isNetworkFailure),
        message: errorMessage,
    };
};
