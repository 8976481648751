import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './styles/index.scss';
import { createRoot } from 'react-dom/client';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import dotenv from 'dotenv';

import { InfoErrorConfirmProvider } from '@components/InfoErrorConfirm';

import * as serviceWorker from './js/serviceWorker';
import App from './js/App';
import AppContextProvider from './js/stores';

import keycloak from './js/security/keycloak';
import FeatureFlagProviderWrapper from './js/security/featureFlagProviderWrapper';
import ApiProvider from './js/apiclient/ApiProvider';

const container = document.getElementById('root');
const root = createRoot(container);

(window as any).collection = 1;
dotenv.config();
// eslint-disable-next-line no-undef
(globalThis as any).require = () => {
    throw new Error(
        'Calls to `require` from umd module definitions are not supported'
    );
};

root.render(
    <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={(event, error) => {
            // eslint-disable-next-line no-console
            console.log('onKeycloakEvent', event, error);
            if (event === 'onTokenExpired') {
                keycloak.updateToken(300);
            }
        }}
        onTokens={(tokens) => {
            // eslint-disable-next-line no-console
            console.log('onKeycloakTokens', tokens);
        }}
    >
        <InfoErrorConfirmProvider>
            <ApiProvider>
                <AppContextProvider>
                    <FeatureFlagProviderWrapper>
                        <App />
                    </FeatureFlagProviderWrapper>
                </AppContextProvider>
            </ApiProvider>
        </InfoErrorConfirmProvider>
    </ReactKeycloakProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
