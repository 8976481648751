import { FC } from 'react';
import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react-pro';
import { FloCToastOptions, FloCToastTypes } from './FloCElements.model';

import './FloCToast.css';

interface FloCToastProps {
    toastShow: boolean;
    type: FloCToastTypes;
    message: string;
    options?: FloCToastOptions;
}

const FloCToast: FC<FloCToastProps> = ({
    toastShow,
    type,
    message,
    options,
}) => {
    return (
        <CToaster placement={options?.placement ?? 'top-right'}>
            <CToast
                color={options?.color ?? 'light'}
                visible={toastShow}
                autohide={options?.autohide ?? false}
                delay={options?.delay ?? 6000}
                onClose={() => {
                    if (options?.onClose) {
                        options.onClose();
                    }
                }}
                className="floCToast"
                data-testid={`floctoast-${
                    options?.autohide ? 'hide' : 'always'
                }-${options?.delay ?? 6000}`}
            >
                <CToastHeader closeButton>{type}</CToastHeader>
                <CToastBody>{message}</CToastBody>
            </CToast>
        </CToaster>
    );
};

export default FloCToast;
