import { FC } from 'react';
import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react-pro';
import { CModalProps } from '@coreui/react-pro/dist/components/modal/CModal';
import { ConfirmParams } from './FloCElements.model';

interface FloConfirmModalProps {
    isVisible: boolean;
}

const FloConfirmModal: FC<
    FloConfirmModalProps & ConfirmParams & CModalProps
> = ({
    isVisible,
    title,
    body,
    className,
    size,
    onClose,
    onCancel,
    onConfirm,
}) => (
    <CModal
        visible={isVisible}
        onClose={onClose}
        className={className}
        size={size}
        data-testid="confirm-modal"
    >
        <CModalHeader>
            <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{body}</CModalBody>
        <CModalFooter>
            <CButton
                className="confirmModalCancel"
                color="secondary"
                onClick={onCancel}
            >
                Cancel
            </CButton>
            <CButton
                className="confirmModalConfirm"
                color="danger"
                onClick={onConfirm}
            >
                Confirm
            </CButton>
        </CModalFooter>
    </CModal>
);

export default FloConfirmModal;
